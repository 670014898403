<template>
  <ModalFormGeneric :id="COMPNAME" :title="$t('pwrm.t')" :btnText="$t('btn.r')" :btnDisabled="!valEmail"
      @submit="onSubmit"
      @reset="onReset"
      :ref="COMPNAME">
    <b-form-group :label="$t('lgin.f.emaill')" :label-for="FORMNAME + 'Email'" class="text-left">
      <b-form-input type="email" :id="FORMNAME + 'Email'" @keydown.enter.native="onSubmit" v-model="f.emailAddress" :state="valEmail" required />
      <b-form-invalid-feedback :state="valEmail">
        {{ $t('lgin.f.emailf') }}
      </b-form-invalid-feedback>
    </b-form-group>
    <small class="text">{{ $t('pwrm.ex') }}</small>
  </ModalFormGeneric>
</template>

<script>
import ModalFormGeneric from '@/components/ModalFormGeneric';
import { REST } from '@/components/RestCall';

export default {
  data() {
    return {
      COMPNAME: 'pwrWindow',
      FORMNAME: 'pwrInput',
      f: {
        emailAddress: ''
      },
    }
  },
  computed: {
    valEmail() {
      if (this.f.emailAddress === '') return null;
      let rExp = /(.+)@(.+){2,}\.(.+){2,}/;
      return rExp.test(this.f.emailAddress.toLowerCase());
    },
  },
  methods: {
    onSubmit() {
      if (this.valEmail) {
        let self = this;
        REST.post('/tasks/resetpw', this.f)
          .then(async function(resp) {
            // show for user
            self.$store.commit('showSuccess',resp.d.message);
          }).catch(e => {
            this.$store.commit('showWarn',e.message)
          });
        this.$refs[this.COMPNAME].hide();
        this.$emit('submit');
      }
    },
    onReset() {
      this.f.emailAddress = '';
      this.$emit('reset');
    },
  },
  components: {
    ModalFormGeneric,
  },
}
</script>
